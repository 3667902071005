var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editable && _vm.isWriting
        ? _c(
            "div",
            {
              staticClass:
                "alert alert-info alert-dismissible fade show cursor-pointer",
              staticStyle: {
                "margin-bottom": "13px !important",
                padding: "10px 3px !important",
              },
              attrs: { role: "alert" },
              on: { click: _vm.setPermitData },
            },
            [
              _c(
                "span",
                { staticClass: "q-pl-sm q-pr-md" },
                [
                  _c("q-icon", {
                    attrs: { name: "o_emoji_objects", size: "sm" },
                  }),
                ],
                1
              ),
              _c(
                "q-chip",
                {
                  attrs: {
                    outline: "",
                    square: "",
                    dense: "",
                    color: "teal",
                    "text-color": "white",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$label("LBL0000242", "SWP")) + " ")]
              ),
              _vm._v(" " + _vm._s(_vm.$label("LBL0000269", "SWP")) + " "),
            ],
            1
          )
        : _vm._e(),
      _c(
        "c-card",
        { attrs: { title: "LBL0000630" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    deptValue: "assessWriteDeptCd",
                    type: "dept_user",
                    label: "LBL0000242",
                    name: "assessWriteUserId",
                  },
                  model: {
                    value: _vm.workPermit.assessWriteUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "assessWriteUserId", $$v)
                    },
                    expression: "workPermit.assessWriteUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.workPermit,
                    deptValue: "assessApprovalDeptCd",
                    type: "dept_user",
                    label: "LBL0000243",
                    name: "assessApprovalUserId",
                  },
                  model: {
                    value: _vm.workPermit.assessApprovalUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.workPermit, "assessApprovalUserId", $$v)
                    },
                    expression: "workPermit.assessApprovalUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  attrs: {
                    title: "",
                    columns: _vm.checkGrid.columns,
                    data: _vm.checkGrid.data,
                    customDataTr: "",
                    gridHeightAuto: "",
                    hideHeader: "",
                    hideBottom: "",
                    isTitle: false,
                    columnSetting: false,
                    isFullScreen: false,
                    isExcelDown: false,
                    filtering: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customDataTr",
                      fn: function (props) {
                        return [
                          props.rowIndex === 0
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style th-style",
                                      attrs: { rowspan: 1, colspan: 6 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$label("LBL0000009")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          props.rowIndex === 1
                            ? _c(
                                "q-tr",
                                { attrs: { props: props, "no-hover": "" } },
                                [
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD1",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd1",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD2",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd2",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD3",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd3",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd3,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd3",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd3",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD4",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd4",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd4,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd4",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd4",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD5",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd5",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd5,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd5",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd5",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-td",
                                    {
                                      staticClass:
                                        "text-center th-td-style td-style",
                                      attrs: { rowspan: 1, colspan: 1 },
                                    },
                                    [
                                      _c("c-checkbox", {
                                        attrs: {
                                          isArray: false,
                                          disabled: !_vm.isWriting,
                                          editable: _vm.editable,
                                          codeGroupCd:
                                            "SOP_ASSESSMENT_CHECK_TYPE_CD6",
                                          valueText: "codeName",
                                          valueKey: "code",
                                          label: "",
                                          name: "sopAssessmentCheckTypeCd6",
                                        },
                                        model: {
                                          value:
                                            _vm.workPermit
                                              .sopAssessmentCheckTypeCd6,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.workPermit,
                                              "sopAssessmentCheckTypeCd6",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "workPermit.sopAssessmentCheckTypeCd6",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "assessTable",
          staticClass: "q-mt-sm",
          attrs: {
            title: "LBL0000298",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.workPermit.assessments,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && _vm.isWriting,
            selection: "multiple",
            rowKey: "sopWorkSopAssessmentId",
          },
          on: { "table-data-change": _vm.tableDataChange },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: {
                          label: "위험요인 추가",
                          icon: "add",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.addRiskFactor },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBL0000253",
                          icon: "add",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.add },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.isWriting &&
                  _vm.workPermit.assessments &&
                  _vm.workPermit.assessments.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBL0000275",
                          icon: "remove",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }